import ReactDOM from "react-dom";
import { useEffect, useRef, useState } from "react";
import TextView from "./Typography";
import { getStudentActionStepsReportStat } from "../../app/crud/dashboard.crud";
import displayToast from "./DisplayToast";
import { Tooltip } from "@mui/material";
import axios from "axios";

const ACTION_STEP_SIDEBAR_OPTION_ID = 'action-steps-sidebar';

const ActionStepsSidebarBadge = () => {
	const DEFAULT_ACTION_STATS = {
		in_progress: 0,
		not_started: 0,
		overdue: 0,
	};

	const [targetElement, setTargetElement] = useState(null);
	const [actionStats, setActionStats] = useState({ ...DEFAULT_ACTION_STATS });

	const getStudentActionStepsReportStatController = useRef();

	const inProgressCount = actionStats?.in_progress ?? 0;

	const loadActionStats = () => {
		getStudentActionStepsReportStatController?.current?.abort();

		getStudentActionStepsReportStatController.current = new AbortController();
		const signal = getStudentActionStepsReportStatController.current.signal;

		getStudentActionStepsReportStat({}, { signal }).then((response) => {
			if (response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				const newActionStats = response.data.data?.[0] ?? { ...DEFAULT_ACTION_STATS };

				setActionStats(newActionStats);
			}
		}).catch((error) => {
			console.error('Error fetching data:', error);
		});
	}

	useEffect(() => {
		// use axios mware to update state (TODO: integrate to websockets)
		const requestInterceptor = axios.interceptors.request.use((config) => {
			if (config.url?.includes("/element/action-steps") && ['post', 'patch', 'delete'].includes(config.method)) {
				loadActionStats();
			}

			return config;
		});

		return () => {
			axios.interceptors.request.eject(requestInterceptor);
		};
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			const element = document.getElementById(ACTION_STEP_SIDEBAR_OPTION_ID);
			if (element) {
				setTargetElement(element);
				clearInterval(interval);

				element.style.position = 'relative';
			}
		}, 500);

		loadActionStats();

		return () => {
			getStudentActionStepsReportStatController?.current?.abort();
		}
	}, []);

	const shortNumberDisplay = (n) => {
		if (n > 9) {
			return `${n}+`;
		}

		return n;
	}

	if (targetElement) {
		return ReactDOM.createPortal(
			<div className="action-steps-sidebar-badge">
				<div className="action-steps-sidebar-badge-cont">
					<div>
						{inProgressCount > 0 && (
							<Tooltip
								title={`In-Progress (${inProgressCount})`}
								arrow
								placement="top"
							>
								<div
									className="action-steps-sidebar-badge-item"
									style={{ backgroundColor: 'red' }}
								>
									<TextView fontsize="small" style={{
										lineHeight: '0',
									}}>
										{shortNumberDisplay(inProgressCount)}
									</TextView>
								</div>
							</Tooltip>
						)}
					</div>
				</div>
			</div>
			, targetElement);
	}

	return (
		null
	);
}

export default ActionStepsSidebarBadge;