import axios from "axios";
import { objectToUrlQuery } from "../../helpers/Functions";

export function getMentorCourseStudentStats(mentorId, params) {
    return axios.get(process.env.REACT_APP_API_URL + `/sales/mentor/${mentorId}/students?` + objectToUrlQuery(params));
}


export function getStudentsReport(params={}) {
    return axios.get(process.env.REACT_APP_API_URL + "/dashboard/students-report?" + objectToUrlQuery(params));
}

export function getDetailedStudentsReport(params={}) {
    return axios.get(process.env.REACT_APP_API_URL + "/dashboard/students-report-detailed?" + objectToUrlQuery(params));
}

export function getActionStepsCompletionReport(params={}) {
    return axios.get(process.env.REACT_APP_API_URL + "/dashboard/action-steps-completion?" + objectToUrlQuery(params));
}

export function getActionStepsCompletionReportLibraries(params={}) {
    return axios.get(process.env.REACT_APP_API_URL + "/dashboard/action-steps-completion-libraries?" + objectToUrlQuery(params));
}

export function getTopStudentsReport(params={}) {
    return axios.get(process.env.REACT_APP_API_URL + "/dashboard/top-students?" + objectToUrlQuery(params));
}

export function getLibraryInteractionsReport(params={}) {
    return axios.get(process.env.REACT_APP_API_URL + "/dashboard/library-interactions?" + objectToUrlQuery(params));
}

export function getStudentActionStepsReport(params={}) {
    return axios.get(process.env.REACT_APP_API_URL + "/dashboard/students/action-steps?" + objectToUrlQuery(params));
}

export function getStudentActionStepsReportStat(params={}, config = {}) {
    return axios.get(process.env.REACT_APP_API_URL + "/dashboard/students/action-steps-stats?" + objectToUrlQuery(params), config);
}

export function getStudentLibraryPoints(params={}) {
    return axios.get(process.env.REACT_APP_API_URL + "/dashboard/students/points?" + objectToUrlQuery(params));
}