import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";

import LessonAccordion from "./LessonAccordion";
import TextView from "../../Typography";
import { connect } from "react-redux";
import { InputLabel } from '@mui/material';
import ReactWordcloud from 'react-wordcloud';
import BaseButton from "../../BaseButton";
import BaseTextField from "../../BaseTextField";
import { generateRandomId, isEmpty, isPageQuickAccess, makeCapitalize } from "../../../../helpers/Functions";
import { getSurveyResults, getWordCloudResult, quickSubmitElementResponse, submitStudentElementResponse } from "../../../../app/crud/element.crud";
import displayToast from "../../DisplayToast";
import { isNotStudent } from "../../../../helpers/Utils";
import { Link, json, useLocation } from "react-router-dom";

const WordCloudContent = ({ isQuickAccess = false,...props }) => {

	useEffect(() => {
		if(isNotStudent(props?.currentCapabilities?.role) && !isQuickAccess) {
			props.setGenerate(true);
			props.refreshResult();
		}
	}, []);

	return(
		<div className={`word-cloud-main-container ${props?.generate ? 'wide' : ''}`}>
			<div className={`d-flex flex-column ${props.generate ? 'w-50' : ''}`}>
				<TextView className="mb-4">
					{ props.element.description } (maximum of {props.element.context.max_student_words} word/s)
				</TextView>
				{
					props.generate ? (<>
						<div className="d-flex">
							<InputLabel className="me-2">Your Answer: </InputLabel>
							<InputLabel>{props.answer?.text || "none"}</InputLabel>
						</div>
						<TextView className="mb-2">
							{props.element.context.default_message}
						</TextView>
						<Link 
							onClick={() => {props.refreshResult()}}
							className="mt-2 mb-2 d-block mt-auto"
						>
							Refresh Word Cloud
						</Link>
					</>) : (
					<div className="d-flex align-items-center justify-content-center">
						{
							(!isEmpty(props?.quickAccess) || !isNotStudent(props.currentCapabilities.role)) && (
								<BaseTextField
									placeholder="Answer here"
									value={props.newWord}
									onChange={(e) => {
										const wordNumber = e.target.value.trim().split(' ');
										const hasInvalidCharacters = /[^a-zA-Z0-9-]/.test(e.target.value);

										if (wordNumber.length <= props.element.context.max_student_words) {
											props.setHelperText('');
										} 
										else {
											props.setHelperText(`Please enter no more than ${props.element.context.max_student_words} word/s.`);
										}

										if (!hasInvalidCharacters) {
											props.setHelperText('');
										} 
										else {
											props.setHelperText('Please use alphanumeric characters and dashes only.');
										}

										props.setNewWord(e.target.value);
									}}
									helperText={props.helperText}
									error={props.helperText !== ''}
								/>
							)
						}
						{
							(!isNotStudent(props.currentCapabilities?.role) || isQuickAccess) && (
								<BaseButton
									customclasses="mb-3 ms-2"
									onClick={() => {
										if(isEmpty(props?.quickAccess?.email)){
											if(!isEmpty(props.helperText)|| isEmpty(props.newWord)){
												return
											}

											props.generateWordCloud();
										}
										else {
											if(!isEmpty(props.helperText)|| isEmpty(props.newWord)){
												return
											}

											props.generateWordCloud()
										}
										
									}}
								>
									Submit
								</BaseButton>
							)
						}
					</div>
					)
				}
				{
					(!isNotStudent(props?.currentCapabilities?.role) && props.element.last_element && !isEmpty(props.answer)) && (
						<div className="d-flex ms-2 mt-2">
							<BaseButton 
								customclasses="ms-auto"
								onClick={() => {
									props.updateProgress(undefined, (props.element.sort + 1) == props.element.total_elements, props.studentProgress);
								}}
							>
								Next Element
							</BaseButton>
						</div>
					)
				}
			</div>
			{props.generate && (
				<div className="d-flex w-50 justify-content-center align-items-center">
					<div className="word-cloud">
						<ReactWordcloud
							options={props.options} 
							words={props.topWords}
						/>
					</div>
				</div>
			)}
		</div>
	)
}

const WordCloudElement = ({ setAI, ...props }) => {
	const [newWord, setNewWord] = useState();
	const [words, setWords] = useState([]);
	const [topWords, setTopWords] = useState([])
	const [generate, setGenerate] = useState(false)
	const [helperText, setHelperText] = useState('');
	const [answer, setAnswer] = useState();

	const location = useLocation();
	const isQuickAccess = isPageQuickAccess(location);
	const socket = useRef(null);

	useEffect(() => {
		setWords(addTextAndValueProperties(props.element.additional_attachments))
	},[])

	useEffect(() => { 
		if (!socket.current) {
			socket.current = io(process.env.REACT_APP_WEBSOCKET, {
				withCredentials: true,
				transports: ["websocket", "polling"],
			});
	 
			socket.current.on("connect", () => {
				socket.current.emit("joinCloudRoom", { "element": props.element, "id": generateRandomId(8) });
			});

			socket.current.on("cloudUpdate", (updatedElement) => {
				if (updatedElement?.element_id === props.element?.element_id) {
					setTopWords(addTextAndValueProperties(updatedElement.attachment_additionals))
				}
			});
		}
	 
		return () => {
			if (socket.current) {
				socket.current.disconnect();
				socket.current = null;
			}
		};
	}, [props.element.element_id])

	//added text and value properties to keywords
	const addTextAndValueProperties = (data) => {
		let result = data?.map(item => ({
			...item,
			text: item.description,
			value: item.context
		}));

		return result
	}

	const refreshResult = () => {
		let params = {
			element_id: props.element.element_id,
		}
		
		getWordCloudResult(params).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				setTopWords(addTextAndValueProperties(response.data.data[0].attachment_additionals))
			}
		})
	}
	
	const [studentProgress, setStudentProgress] = useState(0)

	const submitStudentResponse = () => {
		const params = {
			library_party_id: props.library?.library_id || props.element.library_party_id,
			module_resource_id: props?.element?.module_resource_id,
			element_id: props.element.element_id,
			lesson_resource_id: props?.element?.lesson_resource_id,
			element_attachment_id: props?.element.attachment_id,
			student_response: answer
		}

		if(isEmpty(props.currentCapabilities.role) && !isEmpty(props.quickAccess.email)){
			params.email = props.quickAccess.email;
			params.firstname = props.quickAccess.name.firstname;
			params.lastname = props.quickAccess.name.firstname;

			quickSubmitElementResponse(params).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					refreshResult();
					setStudentProgress(response.data.init[0]?.["library_progress"])
					displayToast("success", "Word Submitted");
					setGenerate(true)
				}
			})
		}
		else{
			submitStudentElementResponse(params).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					displayToast("success", "Word Submitted");
					refreshResult();
					setGenerate(true)
				}
			})
		}
	}

	const checkComparison = (string1, string2) => {
		const formattedString1 = string1.replace(/[\s-]/g, '').toLowerCase();
		const formattedString2 = string2.replace(/[\s-]/g, '').toLowerCase();

		return (
			formattedString1 === formattedString2 ||
			formattedString1.includes(formattedString2) ||
			formattedString2.includes(formattedString1)
		);
	}

	const generateWordCloud = ( isAnswer = true ) => {
		let isNewKeyWord = true;
		let oldKeyWord = {};
		
		console.log("testing")
		words.map((obj) => {
			//NOTE: Answer text can be different to word selected but same element Id if comparison returns true
			if(checkComparison(obj.text, newWord)) {
				isNewKeyWord = false;
				oldKeyWord = {
					id : obj.id,
					value : obj.value, 
					text : (makeCapitalize(newWord) == makeCapitalize(obj.text)) ?
						makeCapitalize(obj.text)
						: `${makeCapitalize(newWord)}.${makeCapitalize(obj.text)}`
				}
			}
		})
		
		let top = words.sort((a, b) => b.value - a.value)
		top = words.slice(0, 10)

		if(!isNewKeyWord) {
			const isTopWords = top.filter(obj => obj.id == oldKeyWord.id)
			let newArr = []

			if(!isEmpty(isTopWords)){
				newArr = top.map(obj => {
					if (obj.id == oldKeyWord.id) {
						
						if(isAnswer){
							setAnswer({
								element_id : obj.id,
								text : obj.text
							})
						}

						return { ...obj, value: parseInt(obj.value) + 1 };
					}
					return obj;
				});
			}
			else {
				const textArr = oldKeyWord.text.split('.')//index 0 = Word given 1 = word equivalent

				newArr = [ ...top, {
					text: textArr[1],
					value: parseInt(oldKeyWord.value) + 1
				}]
					
				if(isAnswer){
					setAnswer({
						element_id : oldKeyWord.id,
						text : textArr.length > 0 ? `${textArr[0]}(${textArr[1]})` : textArr[0]
					})
				}
			}
			
			console.log(1)
			console.log(newArr)
			setTopWords(newArr)
		}
		else {
			let newArr = [...top, {
				text: newWord,
				value: 1
			}]

			if(isAnswer){
				setAnswer({
					element_id : 0,
					text : makeCapitalize(newWord)
				})
			}
			
			
			console.log(2)
			console.log(newArr)
			setTopWords(newArr)
		}
	}
	
	useEffect(() => {
		if(isEmpty(props.currentCapabilities.role)) {
			if(!isEmpty(answer)){
				submitStudentResponse();
			}
			else {
				setGenerate(false)
			}
		}
		else if(!isEmpty(answer) && !isNotStudent(props.currentCapabilities.role)){
			
			submitStudentResponse();
		}
		else {
			
			if(!isEmpty(answer)){
				setGenerate(true)
				displayToast("success", "This is just a test, in admin view")
			}
		}
	}, [answer]);

	useEffect(() => {
		if(!isNotStudent(props.currentCapabilities.role) && isEmpty(props.quickAccess.email)) {
			const responseAttachments = props.element.attachments?.[0].student_attachment_response;

			if(!isEmpty(responseAttachments)) {

				setAnswer({
					element_id : responseAttachments.additional_response.id,
					text : responseAttachments.additional_response.description
				})

				setGenerate(true)
				refreshResult()
			}
		}
	}, [props.element])

	//word cloud style
	const options = {
		colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
		deterministic: false,
		fontFamily: "impact",
		fontSizes: [20, 60],
		fontStyle: "normal",
		fontWeight: "normal",
		padding: 1,
		rotations: 2,
		rotationAngles: [0, 90],
	};

	return (
		(!isQuickAccess) ? (
			<LessonAccordion {...props}>{/* TODO: Add lesson object */}
				<WordCloudContent 
					element={props.element}
					generate={generate}
					setGenerate={setGenerate}
					answer={answer}
					options={options}
					topWords={topWords}
					refreshResult={refreshResult}
					newWord={newWord}
					setNewWord={setNewWord}
					helperText={helperText}
					setHelperText={setHelperText}
					generateWordCloud={generateWordCloud}
					currentCapabilities={props.currentCapabilities}
					updateProgress={props.updateProgress}
					studentProgress={studentProgress}
				/>
			</LessonAccordion>
		) : (
			<WordCloudContent 
				element={props.element}
				generate={generate}
				setGenerate={setGenerate}
				answer={answer}
				options={options}
				topWords={topWords}
				refreshResult={refreshResult}
				newWord={newWord}
				setNewWord={setNewWord}
				helperText={helperText}
				setHelperText={setHelperText}
				generateWordCloud={generateWordCloud}
				currentCapabilities={props.currentCapabilities}
				quickAccess={props.quickAccess}
				isQuickAccess={isQuickAccess}
			/>
		)
	)
}

const mapStateToProps = (state) => {
	let data = {
		currentCapabilities: state.auth.current_capabilities,
		quickAccess: state.quickAccess
	}

	return data;
}

export default connect(mapStateToProps)(WordCloudElement);