import React, { useState, useEffect, lazy, Suspense} from "react";
import { useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Formik, Form, useFormik} from "formik";
import { connect } from "react-redux";

import BaseButton from "../../../theme/partials/BaseButton";
import ElementButton from "../../../theme/partials/libraries/lessons/ElementTypeIcon";
import displayToast from "../../../theme/partials/DisplayToast";
import BaseTextField from "../../../theme/partials/BaseTextField";
import { Button } from "react-bootstrap"

import { updateElement } from "../../crud/element.crud";

import {
	VIDEO_ELEMENT,
	REFLECTION_ELEMENT,
	SURVEY_ELEMENT,
	LINK_ELEMENT,
	IMAGE_ELEMENT,
	AUDIO_ELEMENT,
	DOWNLOAD_ELEMENT,
	TEXT_ELEMENT,
	DOCUMENT_ELEMENT,
	SIGNATURE_ELEMENT,
	QUIZ_ELEMENT,
	ACTIONS_STEP_ELEMENT,
	ACTION_SUMMARY_ELEMENT,
	WORD_CLOUD_ELEMENT,
	AI_QUIZ_ELEMENT,
	RATING_ELEMENT,
	QUOTES_ELEMENT,
	QUOTES_TEMPLATES,
	DIR_INSTRUCTOR_PROFILE,
	MENTOR_DEFAULT_IMAGE,
	DEFAULT_EMAIL_FOOTER,
	CUSTOM_PLAN_ELEMENT,
	INTELLIGUIDE_ELEMENT
} from "../../../models/Constants";

import { ensureArray, isEmpty, parseBoolean } from "../../../helpers/Functions";
import TextForm from "./forms/TextForm";

import { setAI, setSelectedLibrary } from "../../redux/slices/dashboardSlice";
import { aiChatCompletion } from "../../crud/ai.crud";
import AITestForm from "./ai/AITestForm";
import BaseSwitch from "../../../theme/partials/BaseSwitch";
import { FormHelperText, InputLabel } from "@mui/material";
import AIQuizForm from "./forms/AIQuizForm";
import BaseCheckbox from "../../../theme/partials/BaseCheckbox";
import DatePicker from "../../../theme/partials/DatePicker";
import { getElementsSummary, getLibraryMentors } from "../../crud/library.crud";
import RichTextEditor from "../../../theme/partials/RichTextEditor";
import BaseSelect from "../../../theme/partials/BaseSelect";

const VideoForm = lazy(() => import("./forms/VideoForm"));
const LinkForm = lazy(() => import("./forms/LinkForm"));
const ImageForm = lazy(() => import("./forms/ImageForm"));
const AudioForm = lazy(() => import("./forms/AudioForm"));
const SurveyForm = lazy(() => import("./forms/SurveyForm"));
const SignatureForm = lazy(() => import("./forms/SignatureForm"));
const DownloadForm = lazy(() => import("./forms/DownloadForm"));
const PDFForm = lazy(() => import("./forms/PDFForm"));
const ReflectionForm = lazy(() => import("./forms/ReflectionForm"));
const QuizForm = lazy(() => import("./forms/QuizForm"));
const ActionsForm = lazy(() => import("./forms/ActionsStepsForm"));
const ActionSummaryForm = lazy(() => import("./forms/ActionSummaryForm"));
const WordCloudForm = lazy(() => import("./forms/WordCloudForm"));
const QuotesForm = lazy(() => import("./forms/QuotesForm"));
const RatingsForm = lazy(() => import("./forms/RatingsForm"));
const CustomPlanForm = lazy(() => import("./forms/CustomPlanForm"));
const IntelliguideForm = lazy(() => import("./forms/IntelliguideForm"));


export const createElementFormObject = ({
	newElementIndex,
	editElement, // element
	selectedLesson,
	selectedLibrary,
	selectedElementType,
	libraryMentor,
	selectedDataIndex,
	pageLibraryData,
}) => {

	return {
		is_edit: !isEmpty(editElement) ? 1 : 0,
		element_id: !isEmpty(editElement?.element_id) ? editElement?.element_id : 0,
		new_element_index: newElementIndex,
		element_type_id: selectedElementType,
		lesson_resource_id: selectedLesson?.lesson_id,
		module_resource_id: selectedLesson?.module_id,
		library_id: selectedLibrary[selectedDataIndex].library_id,
		mentor_name: !isEmpty(libraryMentor) ? libraryMentor?.firstname + " " + libraryMentor?.lastname : "FirstName LastName",
		mentor_img: !isEmpty(libraryMentor?.additional_info?.instructor_profile) ? process.env.REACT_APP_S3_BUCKET + DIR_INSTRUCTOR_PROFILE + libraryMentor?.additional_info?.instructor_profile : MENTOR_DEFAULT_IMAGE,
		library_title: selectedLibrary[selectedDataIndex].title,
		description: !isEmpty(editElement) ? editElement?.description 
			: (selectedElementType == ACTION_SUMMARY_ELEMENT) ? "You're almost done with the learning expedition!  Here  are the things you wanted to implement.  Kindly put the details and timeline and you'll be on your way to unlocking your new skill." 
			: (selectedElementType == SIGNATURE_ELEMENT) ? "Please input your COMPLETE NAME or SIGNATURE below to signify that you have read and agree with the terms above."
			: (selectedElementType == QUOTES_ELEMENT) ? "Type a quote you like to display."
			: "",
		title: !isEmpty(editElement) ? (!isEmpty(editElement?.title) ? editElement?.title : "") : "",
		link: !isEmpty(editElement) ? editElement?.context?.video_link : "",
		file: !isEmpty(editElement) ? editElement?.context?.file_name : "",
		links: !isEmpty(editElement) ? editElement?.additional_attachments : [{ title: "", description: "" }],
		file_name: !isEmpty(editElement) ? editElement?.file_name : "",
		files: !isEmpty(editElement)
			? !isEmpty(editElement?.additional_attachments) ? editElement?.additional_attachments : [{ "files_id": 0, "files": null, "files_description": "" }]
			: [{ "files_id": 0, "files": null, "files_description": "" }],
		survey_context : !isEmpty(editElement) ? (selectedElementType != SURVEY_ELEMENT ? "" : editElement?.context ) :
			selectedElementType != SURVEY_ELEMENT ? "" : {"survey_type" : "single", "points" : 1, "require_explanation" : false, "explanation_description" : "", "initial_value" : true, "initial_values": []} ,
		choices: !isEmpty(editElement) && 
			(selectedElementType == SURVEY_ELEMENT || selectedElementType == ACTIONS_STEP_ELEMENT) ? 
			(!isEmpty(editElement?.additional_attachments) ? editElement?.additional_attachments : [{ "id": 0, "description": "Choice 1", "context" : 0 }, { "id": 0, "description": "Choice 2", "context" : 0}]) :
			(selectedElementType == SURVEY_ELEMENT || selectedElementType == ACTIONS_STEP_ELEMENT) ? [{ "id": 0, "description": "Choice 1", "context" : 0 }, { "id": 0, "description": "Choice 2", "context" : 0 }] : "",
		is_shared: !isEmpty(editElement) && selectedElementType == REFLECTION_ELEMENT ? editElement?.context?.is_shared == 'true' : true,
		is_previewed: !isEmpty(editElement) && !isEmpty(editElement?.context?.is_previewed) ? editElement?.context?.is_previewed == 'true' : false,
		text_mailable: !isEmpty(editElement) ? editElement?.context?.text_mailable : 0,
		ai_customized : !isEmpty(editElement) ? editElement?.context?.ai_customized : 0,
		ai_prompt : !isEmpty(editElement) ? (isEmpty(editElement?.context?.ai_prompt) ? "" : editElement?.context?.ai_prompt) : "",
		ai_default_text :  !isEmpty(editElement) ? editElement?.context?.ai_default_text : 0,
		ai_master_prompt :  !isEmpty(editElement) ? editElement?.context?.ai_master_prompt || 0 : 0,
		ai_transcript :  !isEmpty(editElement) ? (isEmpty(editElement?.context?.ai_transcript) ? "" : editElement.context.ai_transcript ) : "",
		ai_auto_replace_text : !isEmpty(editElement) ? editElement?.context?.ai_auto_replace_text : 1,
		ai_is_custom_label : !isEmpty(editElement) ? editElement?.context?.ai_is_custom_label : 0,
		ai_custom_label : !isEmpty(editElement) ? (isEmpty(editElement?.context?.ai_custom_label) ? "" : editElement?.context?.ai_custom_label) : "",
		has_quick_access_link : !isEmpty(editElement) ? (parseBoolean(editElement?.context?.has_quick_access_link)) : false,
		quick_access_link : !isEmpty(editElement) ? (isEmpty(editElement?.context?.quick_access_link) ? "" : editElement?.context?.quick_access_link) : "",
		quick_access_tag : !isEmpty(editElement) ? (isEmpty(editElement?.context?.quick_access_tag) ? [] : 
			ensureArray(editElement?.context?.quick_access_tag).map(item => ({value: item, label: item}))) : [],
		quick_access_expiry : !isEmpty(editElement) ? (isEmpty(editElement?.context?.quick_access_expiry) ? "" : 
			editElement?.context?.quick_access_expiry) : "",
		quick_access_credits : !isEmpty(editElement) ? (parseBoolean(editElement?.context?.quick_access_credits)) : true,
		quick_access_single_use : !isEmpty(editElement) ? (parseBoolean(editElement?.context?.quick_access_single_use)) : true,
		quick_access_ip_check : !isEmpty(editElement) ? (parseBoolean(editElement?.context?.quick_access_ip_check)) : true,
		customize_email_footer : !isEmpty(editElement) ? (isEmpty(editElement?.context?.customize_email_footer) ? false : parseBoolean(editElement?.context?.customize_email_footer)) : false,
		ai_email_footer : !isEmpty(editElement) ? (isEmpty(editElement?.context?.ai_email_footer) ? DEFAULT_EMAIL_FOOTER : editElement?.context?.ai_email_footer) : 
			((selectedElementType != TEXT_ELEMENT && selectedElementType != CUSTOM_PLAN_ELEMENT) ? "" : DEFAULT_EMAIL_FOOTER),
		quiz_context : !isEmpty(editElement) ? (selectedElementType != QUIZ_ELEMENT ? "" : editElement?.context ) : 
			selectedElementType != QUIZ_ELEMENT ? "" : {"question_type" : "bool", "points" : 2, "explain" :{ "status" : false, "value" : ""}, "show_correct" : true} ,
		quiz_choices : !isEmpty(editElement) && selectedElementType == QUIZ_ELEMENT ? editElement?.additional_attachments : "",
		video_context: !isEmpty(editElement) ? (selectedElementType != VIDEO_ELEMENT ? "" : editElement?.context ) : 
			selectedElementType != VIDEO_ELEMENT ? "" : {"transcript" : ""} ,
		subtitles: !isEmpty(editElement)
			? !isEmpty(editElement?.additional_attachments) ? editElement?.additional_attachments : [{"id": 0, "file": null, "language": 0}]
			: [{"id": 0, "file": null, "language": 0}],
		action_context : !isEmpty(editElement) ? (selectedElementType != ACTIONS_STEP_ELEMENT ? "" : editElement?.context ) :
			selectedElementType != ACTIONS_STEP_ELEMENT ? "" : {"allow_edit" : true, "allow_add" : true, "required_step" : 1} ,
		summary_actions : !isEmpty(editElement) ? (selectedElementType != ACTION_SUMMARY_ELEMENT ? "" : editElement?.context ) :
			selectedElementType != ACTION_SUMMARY_ELEMENT ? "" : {"allow_custom_deadline" : true, "send_email" : true , "module_id" : selectedLesson?.module_id, "lesson_id" : selectedLesson?.lesson_id, "library_id" : pageLibraryData.library_id} ,
		cloud_context :  !isEmpty(editElement) ? (editElement?.element_type_id != WORD_CLOUD_ELEMENT ? "" : editElement?.context ) :
			selectedElementType != WORD_CLOUD_ELEMENT ? "" : {"max_student_words" : 1 , "max_cloud_words" : 10, "default_message" : "Here are the top keywords from you and your peers."} ,
		cloud_keywords:  !isEmpty(editElement) ? (editElement?.element_type_id != WORD_CLOUD_ELEMENT ? [] : 
			editElement?.additional_attachments?.sort((a, b) => parseInt(b.context) - parseInt(a.context)).map(item => ({
				...item,
				text: item.description,
				value: item.context
			})) ) :
			selectedElementType != WORD_CLOUD_ELEMENT ? [] : 
			Array.from({ length: 7 }, (_, index) => ({
				id: 0,
				text: "",
				value: index >= 5 ? 1 : Math.max(Math.abs(index - 4), 1),
			})),
		text_variation_context : !isEmpty(editElement) ? (selectedElementType != TEXT_ELEMENT ? "" : editElement?.context?.text_variation_context) 
			: (selectedElementType == TEXT_ELEMENT ? {"module_id" : 0, "lesson_id" : 0, "element_id" : 0 , "choice_number": 0} : ""),
		text_type: !isEmpty(editElement) ? (selectedElementType != TEXT_ELEMENT ? "" : (isEmpty(editElement?.context?.text_type) ? "text_ai" : editElement?.context?.text_type)) 
			: (selectedElementType == TEXT_ELEMENT ? "text_ai" : ""),
		text_choices : !isEmpty(editElement) && selectedElementType == TEXT_ELEMENT ? 
			(!isEmpty(editElement?.additional_attachments) ? editElement?.additional_attachments : []) : [],
		ai_quiz : "",
		conversation_starters : !isEmpty(editElement) ? (
			!isEmpty(editElement?.context?.conversation_starters) ? JSON.parse(editElement?.context?.conversation_starters) : []
			) : [],
		ratings_context : !isEmpty(editElement) ? (selectedElementType != RATING_ELEMENT ? "" : editElement?.context ) :
			selectedElementType != RATING_ELEMENT ? "" : {"require_explanation" : false, "explanation_description" : [""]},
		rating_criteria : !isEmpty(editElement) ? (selectedElementType != RATING_ELEMENT ? "" : editElement?.additional_attachments ) :
			selectedElementType != RATING_ELEMENT ? "" : [{ "id": 0, "description": "Criteria 1" }, { "id": 0, "description": "Criteria 2"}, { "id": 0, "description": ""}],
		quotes_template : selectedElementType != QUOTES_ELEMENT ? "{}" : 
			!isEmpty(editElement) ? editElement?.context?.quote_template : (QUOTES_TEMPLATES[0]),
		custom_plan_items : selectedElementType == CUSTOM_PLAN_ELEMENT ?  
			(!isEmpty(editElement) ? editElement?.context?.custom_plan_items : [
				{
					"type": "input", 
					"description": "", 
					"token" : "{{custom_1}}", 
					"title" : "Custom 1", 
					"choices" : []
				}
			]) : [],
		points: editElement?.context?.points ?? 1,
		completion_points: editElement?.context?.completion_points ?? 1,
		multi_initial_values: editElement?.context?.initial_values ?? [],
		intelliguide_context: !isEmpty(editElement) ? (selectedElementType != INTELLIGUIDE_ELEMENT ? "" : JSON.parse(editElement?.context.intelliguide_context)) :
			selectedElementType != INTELLIGUIDE_ELEMENT ? "" : {"value": 0, "label": ""},
	};
}

export const transformValuesByElementType = (
	values, 
	elementType, 
	setSubmitting = () => {},
	createGeneratedQuiz = () => {},
) => {
	if(elementType == SURVEY_ELEMENT) {
		const filteredChoices = values.choices.filter(item => item.description.trim() !== "");
		values.choices = filteredChoices

		if(values.survey_context.survey_type == "multi"){
			values.survey_context.initial_values = values.multi_initial_values;
			delete values.multi_initial_values;

			if(values?.survey_context?.choice_limit < 1) {
				setSubmitting(false);
				displayToast("error", "The required limit must be greater than 0.");
				return false;
			}

			if(values.choices.length < values.survey_context.choice_limit){
				setSubmitting(false);
				displayToast("error", "The required limit must be either lower than or the same as the number of choices.")
				return false;
			}
		}
	}
	else if (elementType == WORD_CLOUD_ELEMENT) {
		const initialKeywords = values.cloud_keywords.filter(item => item.text.trim() !== "");

		if(values.cloud_context.max_student_words < 1) {
			setSubmitting(false);
			displayToast("error", "Student has to enter 1 word or higher")
			return false;
		}

		if(values.cloud_context.max_cloud_words < 5) {
			setSubmitting(false);
			displayToast("error", "Words in the cloud has to be minimum of 5 or higher")
			return false;
		}

		if(initialKeywords.length < 5) {
			setSubmitting(false);
			displayToast("error", "Please give at least 5 initial key words for the word cloud")
			return false;
		}
		else {
			values.cloud_keywords = initialKeywords
		}
	}
	else if(elementType == VIDEO_ELEMENT) {
		if(!isEmpty(values.video_context)) {
			values.video_context.transcript = !isEmpty(values?.video_context?.transcript) ? values?.video_context?.transcript : ""
		}

		let filteredSubs = values.subtitles.filter(item => !isEmpty(item.file) || !isEmpty(item?.context?.file));
		values.subtitles = filteredSubs
	}
	else if(elementType == TEXT_ELEMENT && values.text_type == "text_variation") {
		let selectedChoice = 0;
		let errorMsg = "";

		values.text_choices?.map(choice => {
			if(isEmpty(choice.description)) {
				errorMsg = "Please do not leave some text variation empty"
			}

			choice.selected.map(_ => {
				selectedChoice++
			})
		})

		if(values.text_variation_context.choice_number != selectedChoice || !isEmpty(errorMsg)) {
			setSubmitting(false);
			displayToast("error", errorMsg || "Please give all the choices in the survey a variation")
			return false;
		}
	}
	else if (elementType == AI_QUIZ_ELEMENT) {
		if(isEmpty(values.ai_quiz)) {
			setSubmitting(false);
			displayToast("error", "Generate and Select Quizzes from AI")
			return false;
		}

		createGeneratedQuiz(values)
		return false;
	}
	if(elementType == RATING_ELEMENT) {
		const filteredCriteria = values.rating_criteria.filter(item => item.description.trim() !== "");
		values.rating_criteria = filteredCriteria
		
		const filteredComments = values.ratings_context.explanation_description?.filter(item => item.trim() !== "");
		values.ratings_context.explanation_description = !isEmpty(filteredComments) ? filteredComments : [""]
	}

	if(elementType == QUIZ_ELEMENT){
		if(values.quiz_context.question_type == "sort" && !isEmpty(values.ai_default_text)) {
			values.quiz_choices = [
				...values.quiz_choices,
				{
					"id": 0, 
					"description": values.ai_default_text, 
					"context" : parseInt(values.quiz_choices.length)
				}
			];
		}
		else if(values.quiz_context.question_type == "multi") {
			if(values?.quiz_context?.choice_limit < 1) {
				setSubmitting(false);
				displayToast("error", "The required limit must be greater than 0.");
				return false;
			}

			if(values?.quiz_choices?.length < values?.quiz_context?.choice_limit){
				setSubmitting(false);
				displayToast("error", "The required limit must be either lower than or the same as the number of choices.")
				return false;
			}
		}
	}//Auto adding unconfirmed choice before submitting

	if(elementType == QUOTES_ELEMENT){
		values.quotes_template.quote.text = values.description
		values.quotes_template.course_name.text = values.library_title
		values.quotes_template.profile_name.text = values.mentor_name
		if(!isEmpty(values.mentor_img)){
			values.quotes_template.profile_image.url = values.mentor_img
		}
	}

	if (elementType === CUSTOM_PLAN_ELEMENT) {
		values.custom_plan_items.map((item, index) => {
		if (item.type === "single" || item.type === "multi") {
			item.choices.filter(choice => choice.label.trim() !== "");
		}
		});
	}

	if(elementType === ACTIONS_STEP_ELEMENT) {
		values.choices = values.choices?.map((choice) => ({
			...choice,
			description: choice?.title ?? choice?.description,
		}));
	}

	if(!isEmpty(values.quick_access_tag)){
		const dataTags = values.quick_access_tag;
		const arrTags = dataTags.map(item => item.value);
		values.quick_access_tag  = arrTags.toString();
	}
	else {
		values.quick_access_tag = ""
	}

	return true;
}

const ElementControllerModal = ({
	selectedLibrary,
	setSelectedLibrary,
	setAI,
	...props
}) => {
	const [selectedElementType, setSelectedElementType] = useState(0);
	const [showTitle, setShowTitle] = useState(false);
	const [selectedDataIndex, setSelectedDataIndex] = useState(0);
 	const [pageLibraryData, setPageLibraryData] = useState({});
	const toggleTitle = () => setShowTitle(!showTitle);

	const location = useLocation();
	const currentUrl = location.pathname;
	const libCode = currentUrl.split('/').filter(part => part !== '').pop();

	const [addTranscript, setAddTranscript] = useState(false)
 	const [addSubtitle, setAddSubtitle] = useState(false);
 	const [showWordCloudMessage, setShowWordCloudMessage] = useState(false);

	useEffect(() => {
		let editSelectedLibrary = [...selectedLibrary]
		let dataIndex = editSelectedLibrary?.findIndex(item => item.library_code === libCode);
		let selectedData = dataIndex !== -1 ? editSelectedLibrary[dataIndex] : null;

		editSelectedLibrary[dataIndex] = selectedData
		setSelectedDataIndex(dataIndex)
		setPageLibraryData(selectedData)
	}, [])

	useEffect(() => {
		if(selectedElementType == VIDEO_ELEMENT) {
			setAddTranscript(!isEmpty(props.editElement?.context?.transcript))
			setAddSubtitle(!isEmpty(props.editElement?.additional_attachments))
		}
	},[props.editElement, selectedElementType])

	useEffect(() => {
		if(!isEmpty(props.editElement?.element_type_id)) {
			setSelectedElementType(props.editElement?.element_type_id);
			setShowTitle(!isEmpty(props.editElement?.title));
		}
		else {
			setSelectedElementType(0);
			setShowTitle(false)
		}
	}, [props.editElement]);

	useEffect(() => {
		if(selectedElementType == TEXT_ELEMENT || selectedElementType == REFLECTION_ELEMENT){
			setAI({
				type: selectedElementType,
				response: "",
				string: "",
				prompt: "",
				settings : "",
				prompt_params : ""
			})
		}else{
			setAI({})
		}
	}, [props.showElementModal]);

	useEffect(() => {
		if(props.showElementModal && isEmpty(libraryMentor) && isEmpty(props.editElement)){
			displayToast("info", "No mentor for this Library some elements are disabled!");
		}
	}, [props.editElement])

	const handleCloseModal = () => {
		setSelectedElementType(0);
		props.setShowElementModal(false);
	}

	const [libraryMentor, setLibraryMentor] = useState({})

	useEffect(() => {
		setLibraryMentor({})
		if(!isEmpty(pageLibraryData)){
			getLibraryMentors(pageLibraryData.library_id).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					let mentors = response.data?.data || [];

					if(!isEmpty(mentors)) {
						let mentorOwner = mentors?.find(mentor => mentor.is_owner == 1);
						
						if (!isEmpty(mentorOwner)) {
							mentorOwner.additional_info = JSON.parse(mentorOwner.additional_info);
							setLibraryMentor(mentorOwner)
						}
					}
				}
			})
		}
	}, [pageLibraryData]);

	const [testAI, setTestAI] = useState(false)

	const testAIResponse = (aiForm) => {
		if(aiForm.prompt == "") {
			displayToast("error", "Please fill up Customize Prompt before testing")
			return
		}

		const formatedPrompt = (aiForm?.type == TEXT_ELEMENT) ? paramFinder(aiForm.prompt, aiForm.prompt_params) : aiForm.prompt
		let params = {}
		let message = "";

		if(formatedPrompt) {
			if(aiForm?.type == TEXT_ELEMENT) {
				if(aiForm?.settings?.defaultText == 0) {
					params = {
						prompt : formatedPrompt,
						title : props.editElement?.title
					}
					message = formatedPrompt
				}
				else {
					params = {
						string : aiForm.string,
						prompt : formatedPrompt,
						title : props.editElement?.title
					}
					message = formatedPrompt + "\n\n" + aiForm?.string
				}
			}
			else {
				params = {
					string : aiForm.string,
					prompt : formatedPrompt
				}
				message = formatedPrompt + "\n\n" + aiForm?.string
			}
			setTestAI(true)

			aiChatCompletion(params).then((response) => {
				if (response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
					setTestAI(false)
				}
				else {
					const AIrespo = {
						type: aiForm.type,
						string: aiForm.string,
						response : response.data.data[0].content,
						prompt: aiForm.prompt,
						prompt_params :  aiForm.prompt_params,
						message: message,
						settings : aiForm.settings,
					}
	
					setAI(AIrespo)
					setTestAI(false)
				}
			});
		}
		else {
			return
		}
		
	}

	const paramFinder = ( prompt , paramValues) => {
		const pattern = /\{\{(industry|team|role|product|product_description|language)\}\}/g;
		let error = false

		let replacedString = prompt.replace(pattern, (match, word) => {
			if(isEmpty(paramValues[word])) {
				displayToast("error", `You have a parameter ${match} on your prompt but did not give a definition, Please fill up the ${match} textbox.`);
				error = true;

				return match
			}
			return paramValues[word] || match;
		});

		if(error) {
			return false
		}

		const namePattern = /\{\{(name)\}\}/g;

		replacedString = replacedString.replace(namePattern, (match, word) => {
			return props.firstname;
		});

		return replacedString
	};

	const ElementSelection = () => {
		let elementTypes = [
			IMAGE_ELEMENT,
			VIDEO_ELEMENT,
			AUDIO_ELEMENT,
			DOCUMENT_ELEMENT,
			TEXT_ELEMENT,
			REFLECTION_ELEMENT,
			SURVEY_ELEMENT,
			LINK_ELEMENT,
			DOWNLOAD_ELEMENT,
			SIGNATURE_ELEMENT,
			QUIZ_ELEMENT,
			ACTIONS_STEP_ELEMENT,
			WORD_CLOUD_ELEMENT,
			!isEmpty(libraryMentor) ? QUOTES_ELEMENT : null,
			RATING_ELEMENT,
			CUSTOM_PLAN_ELEMENT,
			INTELLIGUIDE_ELEMENT
		].filter(element => element !== null);

		const handleSelectElement = (elementType) => {
			setSelectedElementType(elementType);
			setAI({type: elementType, response: "", string: "", prompt: ""})
		}

		return (
			<div className="row row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-4 g-5 p-5">
				{
					elementTypes.map((elementType, i) => {
						return (
							<ElementButton
								key={i}
								customclasses="card col pointer"
								elementtype={elementType}
								onClick={() => {
									handleSelectElement(elementType)
								}}
							/>
						)
					})
				}
			</div>
		)
	}

	const ElementBody = ({ ...props }) => {
		switch (selectedElementType) {
			case 0:
				return <ElementSelection />;
			case IMAGE_ELEMENT:
				return <ImageForm {...props} />
			case VIDEO_ELEMENT:
				return <VideoForm
							addTranscript={addTranscript}
							setAddTranscript={setAddTranscript}
							addSubtitle={addSubtitle}
							setAddSubtile={setAddSubtitle}
							{...props} 
						/>
			case AUDIO_ELEMENT:
				return <AudioForm {...props} />
			case LINK_ELEMENT:
				return <LinkForm {...props} />
			case SIGNATURE_ELEMENT:
				return <SignatureForm {...props} />
			case DOWNLOAD_ELEMENT:
				return <DownloadForm {...props} />
			case REFLECTION_ELEMENT:
				return <ReflectionForm {...props} />
			case SURVEY_ELEMENT:
				return <SurveyForm {...props} />
			case QUIZ_ELEMENT:
				return <QuizForm {...props} />
			// case LINK_ELEMENT:
			// 	return <LinkElement key={i} element={newElementObj} />
			case TEXT_ELEMENT:
				return <TextForm {...props} />
			case DOCUMENT_ELEMENT:
				return <PDFForm {...props} />
			case QUIZ_ELEMENT:
				return <QuizForm {...props} />
			case ACTIONS_STEP_ELEMENT:
				return <ActionsForm {...props} />
			case ACTION_SUMMARY_ELEMENT:
				return <ActionSummaryForm {...props} />
			case WORD_CLOUD_ELEMENT:
				return <WordCloudForm 
							showWordCloudMessage={showWordCloudMessage}
							setShowWordCloudMessage={setShowWordCloudMessage}
							{...props}	
						/>
			case AI_QUIZ_ELEMENT:
				return <AIQuizForm {...props} />
			case RATING_ELEMENT:
				return <RatingsForm {...props} />
			case QUOTES_ELEMENT:
				return <QuotesForm 
					{...props} 
				/>
			case CUSTOM_PLAN_ELEMENT:
				return <CustomPlanForm {...props}/>
			case INTELLIGUIDE_ELEMENT:
				return <IntelliguideForm {...props}/>
		}
	}

	const Extralink = ({values, ...props}) => {
		if (values.is_edit === 0) {
			if (
				values.element_type_id === ACTION_SUMMARY_ELEMENT ||
				values.element_type_id === ACTIONS_STEP_ELEMENT
			) {
			  return (
				<div className="d-flex justify-content-center align-items-center">
					<BaseSwitch
						title="Steps"
						customclasses="me-0"
						className="me-2"
						checked={values.element_type_id === ACTION_SUMMARY_ELEMENT}
						onChange={(e) => {
							if (values.element_type_id !== ACTION_SUMMARY_ELEMENT) {
								setSelectedElementType(ACTION_SUMMARY_ELEMENT);
							}
							else {
								setSelectedElementType(ACTIONS_STEP_ELEMENT);
							}
						}}
					/>
				  <InputLabel className="font-size-medium mb-1 color-septenary">Summary</InputLabel>
				</div>
			  )
			}
			else if (values.element_type_id === TEXT_ELEMENT) {
				return (
					<div className="d-flex justify-content-center align-items-center">
						<BaseSwitch
							title="Enable Text Variation"
							customclasses="me-0"
							className="me-2"
							checked={values.text_type == "text_variation"}
							onChange={(e) => {
								if (values.text_type !== "text_variation") {
									props.setFieldValue("text_type", "text_variation");
								} 
								else {
									props.setFieldValue("text_type", "text_ai");
								}
							}}
						/>
					</div>
				);
			}
			else if (values.element_type_id === QUIZ_ELEMENT){
				return(
					<BaseSwitch
						title="Use AI Quiz"
						customclasses="me-0"
						className="me-2"
						checked={values.element_type_id === AI_QUIZ_ELEMENT}
						onChange={(e) => {
							if (values.element_type_id !== AI_QUIZ_ELEMENT) {
								setSelectedElementType(AI_QUIZ_ELEMENT);
							}
							else {
								setSelectedElementType(QUIZ_ELEMENT);
							}
						}}
					/>
				)
			}
		}
	}
	
	const createGeneratedQuiz = (values) => {

		const saveQuizzes = async () => {
			const lib = { ...selectedLibrary[selectedDataIndex] }
			for (const data of values.ai_quiz) {
				let params = {
					is_edit: 0,
					element_id: 0,
					element_type_id: QUIZ_ELEMENT,
					description: data.question,
					quiz_context: data.quiz_context,
					quiz_choices: data.quiz_choices,
					library_id: values.library_id,
					lesson_resource_id: values.lesson_resource_id
				}

				try {
					const response = await updateElement(params);
		
					if (response.data.init[0].status === 'error') {
						displayToast("error", response.data.init[0]["message"]);
					}
					else{
						lib.context = response.data.data[0];
					}
				} 
				catch (error) {
					console.error("Error saving quiz:", error);
					displayToast("error", "An error occurred while saving quizzes");
				}
			}

			displayToast("success", "Element successfully added.");

			let editSelectedLibrary = [...selectedLibrary]

			editSelectedLibrary[selectedDataIndex] = lib
			setPageLibraryData(lib)
			setSelectedLibrary(editSelectedLibrary)
			props.setUpdate(true); // todo: temporary only
			handleCloseModal();
		};

		saveQuizzes();
	}

	return (<>
		<Modal
			size={(selectedElementType == QUOTES_ELEMENT) ? "xl" : "lg"}
			show={props.showElementModal}
			onHide={() => handleCloseModal()}
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>
					{!isEmpty(props.editElement) ? "Edit Element" : "Create a new element"}
				</Modal.Title>
			</Modal.Header>
			<Formik
				enableReinitialize={true}
				initialValues={{
					...createElementFormObject({
						newElementIndex: props.newElementIndex,
						editElement: props.editElement,
						selectedLesson: props.selectedLesson,
						selectedLibrary,
						selectedElementType,
						libraryMentor,
						selectedDataIndex,
						pageLibraryData,
					}),
					disable_submit : false,
				}}
				validate={(values) => {
					const errors = {};
					if (!values.description) {
						if(selectedElementType == TEXT_ELEMENT && values.text_type == "text_variation"){
							values.description = values.text_choices[0]?.description
						}
						else if (selectedElementType == AI_QUIZ_ELEMENT){
							values.description = ""
						}
						else{
							errors.description = 'Please complete required fields.';
						}
					}

					if (selectedElementType == SURVEY_ELEMENT) {
						const filteredChoices = values.choices.filter(item => item.description.trim() !== "");
						
						if(isEmpty(filteredChoices)) {
							errors.description = "Please add a single choice with value"
						}
					}

					return errors;
				}}
				onSubmit={(values, { setSubmitting }) => {
					setSubmitting(true);
					
					let transformValid = transformValuesByElementType(values, selectedElementType, setSubmitting, createGeneratedQuiz);
					
					if(!transformValid) {
						return;
					}

					updateElement(values).then((response) => {
						if(response.data.init[0].status === 'error') {
							displayToast("error", response.data.init[0]["message"]);
						}
						else {
							let editSelectedLibrary = [...selectedLibrary]
							let libData = { ...pageLibraryData }

							if(selectedElementType == QUIZ_ELEMENT) {
								libData.context = response.data.data[0]
								editSelectedLibrary[selectedDataIndex] = libData
								setPageLibraryData(libData)
								setSelectedLibrary(editSelectedLibrary)
							}

							if(values.is_edit == 0 || selectedElementType == TEXT_ELEMENT) {
								getElementsSummary(pageLibraryData.library_code).then((response) => {
									if (response.data.init[0].status === 'error') {
										displayToast("error", response.data.init[0]["message"]);
									}
									else {
										let editSelectedLib = [...selectedLibrary]
										let lib = { 
											...pageLibraryData,
											summary: response.data.data[0]
										}

										editSelectedLib[selectedDataIndex] = lib
										setPageLibraryData(lib)
										setSelectedLibrary(editSelectedLib)
									}
								});
							}

							displayToast("success", values.is_edit == 0 ? "Element successfully added." : "Your changes have been saved.");
							props.setUpdate(true); // todo: temporary only
							handleCloseModal();
						}

						setSubmitting(false);
					});
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					setFieldValue
				}) => (<>
					<AITestForm
						test={testAIResponse}
						testAISubmitting={testAI}
						show={props.testAIShowOptions}
						toggleShow={props.testAIShowToggle}
						response={props.ai.response}
						setFieldValue={setFieldValue}
						prompt={props.ai.prompt}
						params={props.ai.prompt_params}
						type={props.editElement}
					/>
					<Form onSubmit={handleSubmit} validate="validate" enctype="multipart/form-data">
						<Modal.Body>
							{props.testAIShowOptions && (
								<div className="darken-background"></div>
							)}
							<Suspense fallback="Loading...">
								{
									selectedElementType !== 0 && <>
										{
											(selectedElementType == AI_QUIZ_ELEMENT) ? (
												( values.is_edit == 0) &&(
													<div className='d-flex justify-content-end'>
														<BaseSwitch
															title="Use AI Quiz"
															customclasses="me-0"
															className="me-2"
															checked={values.element_type_id === AI_QUIZ_ELEMENT}
															onChange={(e) => {
																if (values.element_type_id !== AI_QUIZ_ELEMENT) {
																	setSelectedElementType(AI_QUIZ_ELEMENT);
																}
																else {
																	setSelectedElementType(QUIZ_ELEMENT);
																}
															}}
														/>
													</div>
												)
											) : (
												(selectedElementType == TEXT_ELEMENT) ? (
													(values.is_edit == 0) && (
														<div className='d-flex justify-content-end'>
															<BaseSwitch
																title="Enable Text Variation"
																customclasses="ms-auto"
																className="me-2"
																checked={values.text_type == "text_variation"}
																onChange={(e) => {
																	if (values.text_type != "text_variation") {
																		setFieldValue("text_type", "text_variation");
																	}
																	else {
																		setFieldValue("text_type", "text_ai");
																	}
																}}
															/>
														</div>
													)
												) : (
													<BaseTextField
														name="description"
														multiline
														minRows={3}
														maxRows={10}
														title={selectedElementType == TEXT_ELEMENT ? "Context" 
															: selectedElementType == QUIZ_ELEMENT ? "Question"
															: selectedElementType == CUSTOM_PLAN_ELEMENT ? "Main Prompt" : "Description"}
														placeholder={selectedElementType == TEXT_ELEMENT ? "Enter Context" 
															: selectedElementType == QUIZ_ELEMENT ? "Enter a Question"
															: selectedElementType == CUSTOM_PLAN_ELEMENT ? "Type in the Main Prompt that will be sent to GPT" : "Enter Description"}
														value={values.description}
														onBlur={handleBlur}
														onChange={handleChange}
														helperText={touched.description && errors.description}
														error={Boolean(touched.description && errors.description)}
														extralink={
															<Extralink
																values={values}
																setFieldValue={setFieldValue}
															/>
														}
													/>
												)
											)
										}
										
										{showTitle && (
											<BaseTextField
												name="title"
												title="Element Title"
												placeholder="Element Title"
												value={values.title}
												onBlur={handleBlur}
												onChange={handleChange}
											/>
										)}
										<Button
											variant="link"
											style={{ marginLeft: "-10px" }}
											className="d-block"
											onClick={toggleTitle}
										>
											{showTitle ? 'Hide' : 'Add'} Title
										</Button>
									</>
								}
								<ElementBody
									handleBlur={handleBlur}
									handleChange={handleChange}
									values={values}
									setFieldValue={setFieldValue}
									touched={touched}
									errors={errors}
									library={pageLibraryData}
									testAIShowToggle={props.testAIShowToggle}
									testAIShowOptions={props.testAIShowOptions}
									showModal={props.showElementModal}
								/>
								{
									(values.element_type_id == TEXT_ELEMENT || values.element_type_id == CUSTOM_PLAN_ELEMENT) ? (<>
										<BaseCheckbox
											title="Customize Email Footer"
											name="customize_email_footer"
											align="start"
											className="d-block"
											value={values.customize_email_footer}
											checked={values.customize_email_footer}
											onChange={
												(e) => {
													setFieldValue("customize_email_footer", !values.customize_email_footer)
												}
											}
											onBlur={handleBlur}
											color="primary"
										/>
										{
											(values.customize_email_footer) ? (
												<div className="my-2">
													<InputLabel className="font-size-medium color-septenary">AI Email Footer</InputLabel>
													<RichTextEditor
														theme="snow"
														style={{ height: "100%" }}
														value={values.ai_email_footer}
														onChange={(e) => {
															setFieldValue("ai_email_footer", e);
														}}
													/>
												</div>
											) : null
										}
									</>) : null
								}
								{
								 	(
										values.element_type_id != 0 &&
										values.element_type_id != ACTION_SUMMARY_ELEMENT &&
										//all elements has no quick() student submit yet
										values.element_type_id != SIGNATURE_ELEMENT
									) && (
									<div className="d-block mt-3">
										<BaseCheckbox
											title="Add Quick Access Link"
											name="has_quick_access_link"
											align="start"
											className="d-block"
											value={values.has_quick_access_link}
											checked={values.has_quick_access_link}
											onChange={
												(e) => {
													setFieldValue("has_quick_access_link", !values.has_quick_access_link)
												}
											}
											onBlur={handleBlur}
											color="primary"
										/>
										{
											values.has_quick_access_link && (
												<div className="d-block">
													<div className="d-flex">
														<div className="w-50 ms-1">
															<BaseTextField
																customclasses="w-100 me-2"
																title="Enter Desired Access Code"
																titleclass="font-size-medium color-septenary"
																name="quick_access_link"
																value={values.quick_access_link}
																onBlur={handleBlur}
																onChange={handleChange}
																placeholder="(Ex: 103510 or SMC1045)"
																helperText={"Access it at olern.ai/me"}
															/>
														</div>
														<div className="w-50 ms-1 me-2">
															<InputLabel className="font-size-medium color-septenary mb-2"> Expiry Date</InputLabel>
															<DatePicker 
																className="w-100"
																autoComplete="off"
																name="quick_access_expiry"
																selected={values.quick_access_expiry ? new Date(values.quick_access_expiry) : null}
																value={values.quick_access_expiry}
																placeholder={"No Expiry"}
																onKeyDown={(e) => {
																	const allowedKeys = ["Backspace", "Tab", "ArrowLeft", "ArrowRight", "Delete", "Slash", "/"];
																	const isNumber = /^[0-9]$/.test(e.key);
																	
																	if (!isNumber && !allowedKeys.includes(e.key)) {
																		e.preventDefault();
																	}
																}}
																onChange={(date) => {
																	let formattedDate = date._d instanceof Date ? date._d.toLocaleDateString('en-US') : '';
																	setFieldValue("quick_access_expiry", formattedDate);
																}}
																timeFormat={false}
															/>
															<FormHelperText className="font-size-small color-quinary mb-2" >
																This will be accessible {isEmpty(values.quick_access_expiry) ? "forever" : "until " + values.quick_access_expiry}
															</FormHelperText>
														</div>
													</div>
													<div className="d-flex">
														<div className="w-100 ms-1">
															<BaseSelect
																customclasses="w-100 ms-2"
																title="Contact Tag/s (optional)"
																titleclass="font-size-medium color-septenary mb-2"
																name="quick_access_tag"
																option={values.quick_access_tag}
																value={values.quick_access_tag}
																onKeyDown={(e) => {
																	if (e.key === 'Enter' && e.target.value.trim()) {
																		const newValue = {
																			value : e.target.value.trim(),
																			label : e.target.value.trim()
																		};

																		const currentTags = values.quick_access_tag;
																		
																		if (!currentTags.some(tag => tag.value === newValue.value)) {
																			const updatedTags = [...currentTags, newValue];
																			setFieldValue('quick_access_tag', updatedTags);
																		}

																		e.preventDefault();
																	}
																}}
																onBlur={handleBlur}
																onChange={(e) => {setFieldValue('quick_access_tag', e)}}
																helperText={"This will be sent to our CRM software"}
																isMulti
															/>
														</div>
													</div>
													<div className="d-flex">
														<div className="w-50">
															<BaseCheckbox
																title="Check user credits upon request"
																name="quick_access_credits"
																align="start"
																className="d-block"
																checked={values.quick_access_credits}
																onChange={
																	(e) => {
																		setFieldValue("quick_access_credits", !values.quick_access_credits)
																	}
																}
																onBlur={handleBlur}
																color="primary"
															/>
														</div>
													</div>
													<div className="d-flex">
														<div className="w-50">
															<BaseCheckbox
																title="Restrict user for single use only"
																name="quick_access_single_use"
																align="start"
																className="d-block"
																value={values.quick_access_single_use}
																checked={values.quick_access_single_use}
																onChange={
																	(e) => {
																		setFieldValue("quick_access_single_use", !values.quick_access_single_use)
																	}
																}
																onBlur={handleBlur}
																color="primary"
															/>
														</div>
														{
															values.quick_access_single_use && (
																<div className="w-50">
																	<BaseCheckbox
																		title="Single use IP Checking"
																		name="quick_access_ip_check"
																		align="start"
																		className="d-block"
																		value={values.	quick_access_ip_check}
																		checked={values.quick_access_ip_check}
																		onChange={
																			(e) => {
																				setFieldValue("quick_access_ip_check", !values.quick_access_ip_check)
																			}
																		}
																		onBlur={handleBlur}
																		color="primary"
																	/>
																</div>
															)
														}
													</div>
												</div>
											)
										}
									</div>	
								 	)
								}
								{
									(
										values.element_type_id == IMAGE_ELEMENT ||
										values.element_type_id == AUDIO_ELEMENT ||
										values.element_type_id == TEXT_ELEMENT ||
										values.element_type_id == VIDEO_ELEMENT
									) && (
										<div className="d-block mt-1">
											<BaseCheckbox
												title="Use Element on Preview"
												name="is_previewed"
												align="start"
												className="d-block"
												value={values.is_previewed}
												checked={values.is_previewed}
												onChange={
													(e) => {
														setFieldValue("is_previewed", !values.is_previewed)
													}
												}
												onBlur={handleBlur}
												color="primary"
											/>
										</div>
									)
								}
							</Suspense>
						</Modal.Body>
						<Modal.Footer>
							{
								(isEmpty(props.editElement) && selectedElementType !== 0) && (
									<BaseButton
										customclasses="me-auto"
										variant="abort"
										onClick={() => setSelectedElementType(0)}
									>
										Select other element
									</BaseButton>
								)
							}
							{
								(selectedElementType != 0) && (
									<BaseButton
										type="submit"
										issubmitting={isSubmitting}
										disabled={values.disable_submit}
									>
										Save
									</BaseButton>
								)
							}
						</Modal.Footer>
					</Form>
				</>)}
			</Formik>
		</Modal>
	</>)
}

const mapStateToProps = (state) => {
	return {
		selectedLibrary : state.dashboard.libraries.selected_library,
		ai: state.dashboard.ai,
		firstname: state.auth.user_details?.firstname
	};
}

const mapDispatchToProps = {
	setAI,
	setSelectedLibrary
}

export default connect(mapStateToProps, mapDispatchToProps)(ElementControllerModal);